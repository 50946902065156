
import { store } from './store/Store';
import { Provider } from 'react-redux';

import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import SolutionAddOn from './pages/SolutionAddOn';
import SolutionBusiness from './pages/SolutionBusiness';
import Hardware from './pages/Hardware';
import Software from './pages/Software';
import ContactUs from './pages/ContactUs';
import NoPage from "./pages/NoPage";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/hardware1" element={<Hardware />} />
          <Route path="/software" element={<Software />} />
          <Route path="/solutionaddon" element={<SolutionAddOn />} />
          <Route path="/solutionbusiness" element={<SolutionBusiness />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Provider store={store}><App /></Provider>);