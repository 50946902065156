import '../Global.css';

import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from '@react-hook/window-size'


const Software = () => {


  const [width, height] = useWindowSize();


  if (width <= 768)
    return <>
      <div className='flex-column'>
        {/** MAIN CONTENT */}
        {/* HEADER */}
        <div className='flex-column'>
          <div className='font-heading-1'>
            SOFTWARE
          </div>
          <div className='font-content'>
            Perusahaan kami menjual berbagai jenis brand software berlisensi dan bergaransi resmi dari principal.
            Beberapa merek terkenal yang bekerja sama dengan kami adalah Microsoft, Adobe, McAfee dan lainnya.
          </div>

          {/* Microsoft */}
          <div className='flex-column'>
            <div className='flex-column' >
              <img src="./microsoft.png" className='img-product'   alt="DIVIX" />
            </div>
            <div className='flex-column flex-product-container-right'>
              <div className='font-heading-2'>
                MICROSOFT
              </div>
              <div className='font-content'>
                Beberapa produk yang kami jual dari microsoft adalah:
                Microsoft Windows 11, Microsoft Office 365, Microsoft SQL Server 2022,
                dan Microsoft Visual Studio 2022.
              </div>
            </div>
          </div>


          {/* Adobe */}
          <div className='flex-column'>
            <div className='flex-column'>
              <img src="./adobe.png" className='img-product' alt="DIVIX" />
            </div>
            <div className='flex-column flex-product-container-right'>
              <div className='div-separator-10-vertical' />
              <div className='font-heading-2'>
                ADOBE
              </div>
              <div className='font-content'>
                Beberapa produk yang kami jual dari Adobe adalah:
                Adobe Acrobat, Adobe Livecycle, Adobe Photoshop,
                Adobe Dreamweaver, Adobe InDesign, Adobe Illustrator dan lainnya.
              </div>
            </div>
          </div>

          {/* MCAfee */}
          <div className='flex-column'>
            <div className='flex-column flex-product-container-left'>
              <img src="./antivirus.png" className='img-product' alt="DIVIX" />
            </div>
            <div className='flex-column flex-product-container-right'>
              <div className='font-heading-2'>
                ANTIVIRUS / SECURITY
              </div>
              <div className='font-content'>
                Beberapa produk yang kami jual untuk kategori ini adalah:
                McAfee Total Protection, AVG Internet Security,
                BitDefender Total Security, BitDefender Internet Security,
                BitDefender Antivirus Plus
              </div>
            </div>
          </div>


        </div>;
      </div>
    </>

  else
    return <>
      <div className='flex-row'>
        <div className='flex-row flex-container-left' />
        {/** MAIN CONTENT */}
        <div className='flex-column flex-container-center' >
          {/* HEADER */}
          <div className='flex-column'>
            <div className='font-heading-1'>
              SOFTWARE
            </div>
            <div className='font-content'>
              Perusahaan kami menjual berbagai jenis brand software berlisensi dan bergaransi resmi dari principal.
              Beberapa merek terkenal yang bekerja sama dengan kami adalah Microsoft, Adobe, McAfee dan lainnya.
            </div>

            {/* Microsoft */}
            <div className='flex-row'>
              <div className='flex-row flex-product-container-left'>
                <img src="./microsoft.png" className='img-product' alt="DIVIX" />
              </div>
              <div className='flex-column flex-product-container-right'>
                <div className='div-separator-10-vertical' />
                <div className='font-heading-2'>
                  Microsoft
                </div>
                <div className='div-separator-10-vertical' />
                <div className='font-content'>
                  Beberapa produk yang kami jual dari microsoft adalah:
                  Microsoft Windows 11, Microsoft Office 365, Microsoft SQL Server 2022,
                  dan Microsoft Visual Studio 2022.
                </div>
              </div>
            </div>


            {/* Adobe */}
            <div className='flex-row'>
              <div className='flex-column flex-product-container-left'>
                <img src="./adobe.png" className='img-product' alt="DIVIX" />
              </div>
              <div className='flex-column flex-product-container-right'>
                <div className='div-separator-10-vertical' />
                <div className='font-heading-2'>
                  Adobe
                </div>
                <div className='div-separator-10-vertical' />
                <div className='font-content'>
                  Beberapa produk yang kami jual dari Adobe adalah:
                  Adobe Acrobat, Adobe Livecycle, Adobe Photoshop,
                  Adobe Dreamweaver, Adobe InDesign, Adobe Illustrator dan lainnya.
                </div>
              </div>
            </div>

            {/* MCAfee */}
            <div className='flex-row'>
              <div className='flex-column flex-product-container-left'>
                <img src="./antivirus.png" className='img-product' alt="DIVIX" />
              </div>
              <div className='flex-column flex-product-container-right'>
                <div className='div-separator-10-vertical' />
                <div className='font-heading-2'>
                  Antivirus / Security Tools
                </div>
                <div className='div-separator-10-vertical' />
                <div className='font-content'>
                  Beberapa produk yang kami jual untuk kategori ini adalah:
                  McAfee Total Protection, AVG Internet Security,
                  BitDefender Total Security, BitDefender Internet Security,
                  BitDefender Antivirus Plus
                </div>
              </div>
            </div>


          </div>;
        </div>
        <div className='flex-row flex-container-left' />
      </div>
    </>
};

export default Software;