
import '../Global.css';


import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from '@react-hook/window-size'

const ContactUs = () => {

  const [width, height] = useWindowSize();



  if (width <= 768) {
    console.log('mobile device',)
    return <>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        <iframe title='Map Divix'
          style={{ height: 300, marginTop: 10, marginBottom: 10, width: 'auto' }}
          src="https://maps.google.com/maps?q=PT.%20Divix%20Tech%20Indonesia&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
          allowfullscreen></iframe>

        <div style={{
          display: 'flex',
          alignSelf: 'center',
          fontSize: 20,
        }}>
          PT. DIVIX TECH INDONESIA
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#dddddd',
          padding: 10
        }} >
          <div style={{
            display: 'flex',
            fontSize: 16,
            fontWeight: 'bold',
            marginLeft: 20,
            marginRight: 20

          }}>
            Head Office
          </div>
          <div className='font-content' style={{
            marginLeft: 20,
            marginRight: 20
          }}>
            Jl. Jimbaran 15 No. 66, Perumahan Citra Maja Raya 2
            Cluster Jimbaran Blok I.16, Lebak, Banten 42381
          </div>

          <img src="./qrmapmain.png"
            style={{
              alignSelf: 'center',
              width: 300,
              height: 'auto',
              display: 'flex',
              boxSizing: 'border-box',
            }} alt="DIVIX" />

        </div>
        
        <div style={{
          marginTop: 5,
          marginBottom: 100,
          display: 'flex',
          flexDirection: 'column', flex: 1, backgroundColor: '#dddddd',
          padding: 10
        }} >
          <div style={{ height: 10 }} />
          <div style={{
            display: 'flex',
            fontSize: 16,
            fontWeight: 'bold',
            marginLeft: 20,
            marginRight: 20
          }}>
            Branch Office
          </div>
          <div className='font-content' style={{
            marginLeft: 20,
            marginRight: 20
          }}>
            Jl. Prima Raya No.1 Blok D1, RT.10/RW.11, Tegal Alur, Kec. Kalideres, Kota Jakarta Barat, Daerah Khusus Ibukota Jakarta 11820
          </div>
          <img src="./qrmapbranch.png"
            style={{

              marginTop: 30,

              alignSelf: 'center',
              width: 300,
              height: 'auto',
              display: 'flex',
              boxSizing: 'border-box',
            }} alt="DIVIX" />

        </div>

      </div>
    </>
  } else
    return <>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
      }}>


        <div style={{ display: 'flex', flex: 10, }} />

        <div style={{ display: 'flex', flex: 80, flexDirection: 'column' }} >
          <iframe title='Map Divix'
            style={{ height: 300, marginTop: 10, marginBottom: 10 }}
            src="https://maps.google.com/maps?q=PT.%20Divix%20Tech%20Indonesia&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
            allowfullscreen></iframe>

          <div style={{ height: 50 }} />

          <div style={{
            display: 'flex',
            alignSelf: 'center',
            fontSize: 30,
            marginBottom: 50
          }}>
            PT. DIVIX TECH INDONESIA
          </div>

          <div style={{ display: 'flex', flexDirection: 'row' }} >

            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: 20, margin: 5, backgroundColor: '#dddddd' }} >
              <div style={{ height: 10 }} />
              <div style={{
                display: 'flex',
                fontSize: 20,
                fontWeight: 'bold'
              }}>
                Head Office
              </div>
              <div style={{ height: 3 }} />
              <div className='font-content' style={{minHeight: 80}}>
                Jl. Jimbaran 15 No. 66, Perumahan Citra Maja Raya 2
                Cluster Jimbaran Blok I.16, Lebak, Banten 42381
              </div>

              <img src="./qrmapmain.png"
                style={{
                  marginTop: 30,
                  alignSelf: 'center',
                  width: 300,
                  height: 'auto',
                  display: 'flex',
                  boxSizing: 'border-box',
                }} alt="DIVIX" />

            </div>

            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: 20, margin: 5, backgroundColor: '#dddddd' }} >
              <div style={{ height: 10 }} />
              <div style={{
                display: 'flex',
                fontSize: 20,
                fontWeight: 'bold'
              }}>
                Branch Office
              </div>
              <div style={{ height: 5 }} />
              <div className='font-content' style={{minHeight: 80}}>
                Jl. Prima Raya No.1 Blok D1, RT.10/RW.11, Tegal Alur, Kec. Kalideres, Kota Jakarta Barat, Daerah Khusus Ibukota Jakarta 11820
              </div>
              <img src="./qrmapbranch.png"
                style={{

                  marginTop: 30,

                  alignSelf: 'center',
                  width: 300,
                  height: 'auto',
                  display: 'flex',
                  boxSizing: 'border-box',
                }} alt="DIVIX" />

            </div>

          </div>

        </div>

        <div style={{ display: 'flex', flex: 10, }} />

      </div >

      <div style={{ height: 200 }} />

    </>

};

export default ContactUs;