import { useState, useEffect } from 'react'
import { useTime } from 'react-timer-hook';
import { useSelector, useDispatch } from 'react-redux'
import { updateTimer } from '../store/TimerSlice';

import '../Global.css';

import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from '@react-hook/window-size'


const Home = () => {

  const [width, height] = useWindowSize();


  const counter = useSelector((state) => state.timer.counter);
  const dispatch = useDispatch();

  const [hover1, setHover1] = useState(0)
  const [hover2, setHover2] = useState(0)
  const [hover3, setHover3] = useState(0)
  const [hover4, setHover4] = useState(0)

  const {
    seconds,
    minutes,
    hours,
    ampm,
  } = useTime({ format: '24-hour' });

  useEffect(() => {

    if (seconds % 5 == 0) {
      var i = counter;
      i = i + 1;
      if (i > 6) {
        i = 1;
      }
      dispatch(updateTimer({ payload: i, type: 'counter' }));
    }
  }, [seconds])


  if (width <= 768) {
    return <>
      <div className='flex-column'>
        <div style={{ display: 'flex' }}>
          <img src={counter == 1 ? "./corousel1.png" : counter == 2 ? "./corousel2.png" : counter == 3 ? "./corousel3.png" : counter == 4 ? "./corousel4.png" :  counter == 5 ? "./corousel5.png" : "./corousel6.png"}
            className='img-corousel' alt="DIVIX" />
        </div>

        {/** LAMPU MERAH COROUSEL */}
        <div className='corousel1'>
          <div className='corousel2'>
            <div className='corousel3'
              style={{ backgroundColor: (counter == 1 ? "red" : "#aaaaaa") }}
              onClick={() => { dispatch(updateTimer({ payload: 1, type: 'counter' })); }} />
            <div className='corousel3'
              style={{ backgroundColor: (counter == 2 ? "red" : "#aaaaaa") }}
              onClick={() => { dispatch(updateTimer({ payload: 2, type: 'counter' })); }} />
            <div className='corousel3'
              style={{ backgroundColor: (counter == 3 ? "red" : "#aaaaaa") }}
              onClick={() => { dispatch(updateTimer({ payload: 3, type: 'counter' })); }} />
            <div className='corousel3'
              style={{ backgroundColor: (counter == 4 ? "red" : "#aaaaaa") }}
              onClick={() => { dispatch(updateTimer({ payload: 4, type: 'counter' })); }} />
            <div className='corousel3'
              style={{ backgroundColor: (counter == 5 ? "red" : "#aaaaaa") }}
              onClick={() => { dispatch(updateTimer({ payload: 5, type: 'counter' })); }} />
            <div className='corousel3'
              style={{ backgroundColor: (counter == 6 ? "red" : "#aaaaaa") }}
              onClick={() => { dispatch(updateTimer({ payload: 6, type: 'counter' })); }} />
          </div>
        </div>

        <div className='flex-row flex-justify-content-center'>
          <div className='home-font-tentang'>TENTANG &nbsp; </div>
          <div className='home-font-kami'>KAMI</div>
        </div>
        <div className="home-font-tentang-kami-content">
          Kami adalah perusahaan yang bergerak pada bidang pengadaan
          produk teknologi informasi, peralatan pendidikan dan peralatan kantor.
          PT Divix Tech Indonesia didirikan oleh professional  yang sudah
          berpengalaman di berbagai kegiatan pengadaan
          dan proyek teknologi informasi pada perusahaan swasta nasional
          dan pemerintahan.
        </div>
        <div className='flex-row flex-justify-content-center'>
          <div className='home-font-tentang'>PRODUK & &nbsp; </div>
          <div className='home-font-kami'>LAYANAN KAMI</div>
        </div>


        <a href='/hardware1'
          className='flex-column flex-1 padding-10 cursor-pointer'
          style={{ backgroundColor: hover1 == 1 ? 'rgba(100,100,100,0.5)' : 'transparent', }}
          onMouseOver={() => { setHover1(1) }}
          onMouseLeave={() => { setHover1(0) }} >
          <div className='flex-column'>
            <img src="./header-hardware.png" alt="DIVIX" />
            <div className='home-font-1'>
              HARDWARE
            </div>
            <p className="home-font-tentang-kami-content">
              Kami menjual berbagai jenis dan merek Printer,
              Laptop, Personal Computer, Server dan berbagai
              kebutuhan hardware lainnya
            </p>

          </div>
        </a>

        {/** SOFTWARE */}
        <a href='/software'
          className='flex-column flex-1 padding-10 cursor-pointer'
          style={{ backgroundColor: hover2 == 1 ? 'rgba(100,100,100,0.5)' : 'transparent', }}
          onMouseOver={() => { setHover2(1) }}
          onMouseLeave={() => { setHover2(0) }}>
          <div className='flex-column'>
            <img src="./header-software.png" alt="DIVIX" />
            <div className='home-font-1'>
              SOFTWARE
            </div>
            <p className="home-font-tentang-kami-content">
              Kami menjual berbagai produk software berlisensi
              Microsoft Windows, Microsoft Office dan Antivirus
            </p>
          </div>
        </a>

        <a
          href='/solutionaddon'
          className='flex-column flex-1 padding-10 cursor-pointer'
          style={{ backgroundColor: hover3 == 1 ? 'rgba(100,100,100,0.5)' : 'transparent', }}
          onMouseOver={() => { setHover3(1) }}
          onMouseLeave={() => { setHover3(0) }}>
          <div className='flex-column'>
            <img src="./header-solution-addon.png" alt="DIVIX" />
            <div className='home-font-1'>
              SOLUTION ADD ON
            </div>
            <p className="home-font-tentang-kami-content">
              Sebagai add on, kami mengerjakan berbagai pekerjaan API
              (Application Programming Interface), Web Application
              dan Middleware Application. Kami juga menyediakan
              Instalasi Security dan Networking
            </p>

          </div>
        </a>


        <a href='/solutionbusiness'
          className='flex-column flex-1 padding-10 cursor-pointer'
          style={{ backgroundColor: hover4 == 1 ? 'rgba(100,100,100,0.5)' : 'transparent', }}
          onMouseOver={() => { setHover4(1) }}
          onMouseLeave={() => { setHover4(0) }}
        >

          <div className='flex-column'>
            <img src="./header-solution-business.png" alt="DIVIX" />
            <div className='home-font-1'>
              SOLUTION BUSINESS
            </div>
            <p className="home-font-tentang-kami-content">
              Sebagai solusi bisnis, kami menjual ERP Application
              meliputi modul: Inventory, Manufacture, Sales,
              Procurement dan Asset Management
            </p>
          </div>
        </a>



      </div>
      <div className='div-separator-footer' />
    </>
  } else
    return <>
      <img src={counter == 1 ? "./corousel1.png" : counter == 2 ? "./corousel2.png" : counter == 3 ? "./corousel3.png" : counter == 4 ? "./corousel4.png" : counter == 5 ? "./corousel5.png" : "./corousel6.png" }
        className='img-corousel' alt="DIVIX" />

      {/** LAMPU MERAH COROUSEL */}
      <div className='corousel1'>
        <div className='corousel2'>
          <div className='corousel3'
            style={{ backgroundColor: (counter == 1 ? "red" : "#aaaaaa") }}
            onClick={() => { dispatch(updateTimer({ payload: 1, type: 'counter' })); }} />
          <div className='corousel3'
            style={{ backgroundColor: (counter == 2 ? "red" : "#aaaaaa") }}
            onClick={() => { dispatch(updateTimer({ payload: 2, type: 'counter' })); }} />
          <div className='corousel3'
            style={{ backgroundColor: (counter == 3 ? "red" : "#aaaaaa") }}
            onClick={() => { dispatch(updateTimer({ payload: 3, type: 'counter' })); }} />
          <div className='corousel3'
            style={{ backgroundColor: (counter == 4 ? "red" : "#aaaaaa") }}
            onClick={() => { dispatch(updateTimer({ payload: 4, type: 'counter' })); }} />
          <div className='corousel3'
            style={{ backgroundColor: (counter == 5 ? "red" : "#aaaaaa") }}
            onClick={() => { dispatch(updateTimer({ payload: 5, type: 'counter' })); }} />
          <div className='corousel3'
            style={{ backgroundColor: (counter == 6 ? "red" : "#aaaaaa") }}
            onClick={() => { dispatch(updateTimer({ payload: 6, type: 'counter' })); }} />
        </div>
      </div>

      {/** SPASI */}
      <div className='div-separator-10-vertical' />

      {/** TENTANG KAMI HEADER*/}
      <div className='flex-row bg-color-eeeeee'>
        <div className='flex-column padding-10 margin-20 flex-1'>

          <div className='flex-row flex-justify-content-center'>
            <div className='home-font-tentang'>TENTANG &nbsp; </div>
            <div className='home-font-kami'>KAMI</div>
          </div>

          <p className="home-font-tentang-kami-content">
            Kami adalah perusahaan yang bergerak pada bidang pengadaan
            produk teknologi informasi, peralatan pendidikan dan peralatan kantor.
            PT Divix Tech Indonesia didirikan oleh professional  yang sudah
            berpengalaman di berbagai kegiatan pengadaan
            dan proyek teknologi informasi pada perusahaan swasta nasional
            dan pemerintahan.
          </p>
        </div>

        <div className='flex-column flex-1'>

          <img src="./bg-tentang-kami.png" className='flex img-w100-hauto cursor-pointer' alt="DIVIX" />
        </div>
      </div>

      {/** PRODUK DAN LAYANAN */}
      <div className='flex-column padding-10'>
        <div className='flex-row flex-justify-content-center' >
          <div className='home-font-produk-dan'>PRODUK DAN &nbsp; </div>
          <div className='home-font-layanan-kami'>LAYANAN KAMI</div>
        </div>
      </div>



      {/** PRODUK DAN LAYANAN DETAIL */}
      <div className='flex-row'>

        {/** HARDWARE */}
        <a href='/hardware1'
          className='flex-column flex-1 padding-10 cursor-pointer'
          style={{ backgroundColor: hover1 == 1 ? 'rgba(100,100,100,0.5)' : 'transparent', }}
          onMouseOver={() => { setHover1(1) }}
          onMouseLeave={() => { setHover1(0) }} >
          <div className='flex-column'>
            <img src="./header-hardware.png" style={{ width: '100%' }} alt="DIVIX" />
            <div className='home-font-1'>
              HARDWARE
            </div>
            <p className="home-font-tentang-kami-content">
              Kami menjual berbagai jenis dan merek Printer,
              Laptop, Personal Computer, Server dan berbagai
              kebutuhan hardware lainnya
            </p>

          </div>
        </a>

        <div style={{ width: 10 }} />

        {/** SOFTWARE */}
        <a href='/software'
          className='flex-column flex-1 padding-10 cursor-pointer'
          style={{ backgroundColor: hover2 == 1 ? 'rgba(100,100,100,0.5)' : 'transparent', }}
          onMouseOver={() => { setHover2(1) }}
          onMouseLeave={() => { setHover2(0) }}>
          <div className='flex-column'>
            <img src="./header-software.png" style={{ width: '100%' }} alt="DIVIX" />
            <div className='home-font-1'>
              SOFTWARE
            </div>
            <p className="home-font-tentang-kami-content">
              Kami menjual berbagai produk software berlisensi
              Microsoft Windows, Microsoft Office dan Antivirus
            </p>
          </div>
        </a>
      </div>

      <div className='flex-row'>

        <a
          href='/solutionaddon'
          className='flex-column flex-1 padding-10 cursor-pointer'
          style={{ backgroundColor: hover3 == 1 ? 'rgba(100,100,100,0.5)' : 'transparent', }}
          onMouseOver={() => { setHover3(1) }}
          onMouseLeave={() => { setHover3(0) }}>
          <div className='flex-column'>
            <img src="./header-solution-addon.png" style={{ width: '100%' }} alt="DIVIX" />
            <div className='home-font-1'>
              SOLUTION ADD ON
            </div>
            <p className="home-font-tentang-kami-content">
              Sebagai add on, kami mengerjakan berbagai pekerjaan API
              (Application Programming Interface), Web Application
              dan Middleware Application. Kami juga menyediakan
              Instalasi Security dan Networking
            </p>

          </div>
        </a>

        <div style={{ width: 10 }} />

        <a href='/solutionbusiness'
          className='flex-column flex-1 padding-10 cursor-pointer'
          style={{ backgroundColor: hover4 == 1 ? 'rgba(100,100,100,0.5)' : 'transparent', }}
          onMouseOver={() => { setHover4(1) }}
          onMouseLeave={() => { setHover4(0) }}
        >

          <div className='flex-column'>
            <img src="./header-solution-business.png" style={{ width: '100%' }} alt="DIVIX" />
            <div className='home-font-1'>
              SOLUTION BUSINESS
            </div>
            <p className="home-font-tentang-kami-content">
              Sebagai solusi bisnis, kami menjual ERP Application
              meliputi modul: Inventory, Manufacture, Sales,
              Procurement dan Asset Management
            </p>
          </div>
        </a>
      </div>





    </>
};

export default Home;