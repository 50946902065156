import { Outlet, Link } from "react-router-dom";
import './Layout.css';
import '../Global.css'
import { useState } from 'react'
import { useNavigate } from "react-router-dom";

import {
    useWindowSize,
    useWindowWidth,
    useWindowHeight,
} from '@react-hook/window-size'


const Layout = () => {

    const [width, height] = useWindowSize();


    const [menuHomeOver, setMenuHomeOver] = useState(0);
    const [menuProductOver, setMenuProductOver] = useState(0);
    const [menuContactUsOver, setContactUsOver] = useState(0);
    const [menuProductOpen, setMenuProductOpen] = useState(0);
    const history = useNavigate();

    const [productMenuHover, setProductMenuHover] = useState(0);

    const onProductMenuHover = (e) => {
        setProductMenuHover(e);
    }

    const onProductMenuLeave = (e) => {
        console.log('mouse leave...');
        setProductMenuHover(0);
        setMenuProductOpen(0);

    }


    const onMouseOver = (e) => {
        if (e == 'HOME') {
            setMenuHomeOver(1);
        }
        if (e == 'PRODUCT') {
            setMenuProductOver(1);
        }
        if (e == 'CONTACTUS') {
            setContactUsOver(1);
        }
    }

    const onMouseLeave = (e) => {
        if (e == 'HOME') {
            setMenuHomeOver(0);
        }
        if (e == 'PRODUCT') {
            setMenuProductOver(0);
        }
        if (e == 'CONTACTUS') {
            setContactUsOver(0);
        }
    }

    const onClick = (e) => {
        if (e == 'PRODUCT') {
            setMenuProductOpen(!menuProductOpen);
        }
    }

    console.log('windowWidth:', width);
    if (width <= 768) {
        return <>

            {/** MAIN LAYOUT */}
            <div className="flex-column">
                {/** STICKY HEADER */}
                <div className="header-1">
                    <img src="./logo002.png" className="logo-divix" alt="DIVIX" onClick={() => { history('/'); }} />
                    <div className="flex flex-1"></div>

                    <div className="header-2" >
                        <a href="/" className="menu-1" style={{ backgroundColor: (menuHomeOver == 1 ? '#eeeeee' : 'transparent') }}
                            onMouseOver={() => { onMouseOver('HOME') }}
                            onMouseLeave={() => { onMouseLeave('HOME') }}>
                            HOME
                        </a>
                        <div className="flex-column">
                            <div className="menu-1" style={{ backgroundColor: (menuProductOver == 1 ? '#eeeeee' : 'transparent') }}
                                onClick={() => { onClick('PRODUCT') }}
                                onMouseOver={() => { onMouseOver('PRODUCT') }}
                                onMouseLeave={() => { onMouseLeave('PRODUCT') }}>
                                PRODUCT
                            </div>
                            <div style={{ position: 'relative', bottom: 0, zIndex: 1 }}>
                                <div className="menu-2" style={{ display: (menuProductOpen == 1 ? 'flex' : 'none'), }}
                                    onMouseLeave={() => { onProductMenuLeave(0) }} >
                                    <a href="/hardware1"
                                        className="menu-3"
                                        style={{ backgroundColor: (productMenuHover == 1 ? '#e0e0e0' : 'transparent'), cursor: 'pointer' }}
                                        onMouseOver={() => { onProductMenuHover(1) }}>
                                        HARDWARE
                                    </a>
                                    <a href="/software" className="menu-3"
                                        style={{
                                            backgroundColor: (productMenuHover == 2 ? '#e0e0e0' : 'transparent'), cursor: 'pointer'
                                        }} onMouseOver={() => { onProductMenuHover(2) }}>
                                        SOFTWARE
                                    </a>
                                    <a href="/solutionaddon" className="menu-3" style={{
                                        backgroundColor: (productMenuHover == 3 ? '#e0e0e0' : 'transparent'), cursor: 'pointer'
                                    }}
                                        onMouseOver={() => { onProductMenuHover(3) }}>
                                        SOLUTION ADDON
                                    </a>
                                    <a href="/solutionbusiness" className="menu-3" style={{
                                        backgroundColor: (productMenuHover == 4 ? '#e0e0e0' : 'transparent'), cursor: 'pointer'
                                    }} onMouseOver={() => { onProductMenuHover(4) }}>
                                        SOLUTION BUSINESS
                                    </a>
                                </div>
                            </div>
                        </div>


                        <a href="/ContactUs"
                            className="menu-1"
                            style={{
                                backgroundColor: (menuContactUsOver == 1 ? '#eeeeee' : 'transparent')

                            }}
                            onMouseOver={() => { onMouseOver('CONTACTUS') }}
                            onMouseLeave={() => { onMouseLeave('CONTACTUS') }}>
                            CONTACT US
                        </a>

                    </div>
                </div>

                {/** Banner */}
                <div className="linear-bg" >
                    Your Software and Hardware Solution
                </div>


                {/** COROUSEL */}
                <Outlet />

                {/** FOOTER */}
                <div className="footer-1">
                    <div className="footer-2">
                        &copy; 2024 PT. Divix Tech Indonesia
                    </div>
                    <div className="footer-3">
                        Jl. Jimbaran 15 No. 66, Perumahan Citra Maja Raya 2
                    </div>
                    <div className="footer-3">
                        Cluster Jimbaran Blok I.16, Lebak, Banten 42381
                    </div>
                    <div className="footer-3">
                        Phone: +62 851 7863 7624, Email: admin@divixtech.com
                    </div>
                </div>

                <a href="https://wa.me/+6285176847624/?text=urlencodedtext">
                    <img src="./wawa.png" className="wawa" alt="DIVIX" />
                </a>

            </div >
        </>
    } else {
        return <>
            <div className='flex-column'>
                {/** Header Logo and Navigation**/}


                <div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'sticky',
                    height: 80,
                    backgroundColor: 'white',
                    top: 0,
                }}>

                    <div style={{
                        display: 'flex',
                        flex: 10,
                    }}>

                    </div>

                    <div style={{
                        display: 'flex',
                        flex: 80,
                        flexDirection: 'column',
                    }}>
                        <div className="header-1">
                            <img src="./logo002.png" className="logo-divix" alt="DIVIX" onClick={() => { history('/'); }} />
                            <div className="flex flex-1"></div>

                            <div className="header-2" >
                                <a href="/" className="menu-1" style={{ backgroundColor: (menuHomeOver == 1 ? '#eeeeee' : 'transparent') }}
                                    onMouseOver={() => { onMouseOver('HOME') }}
                                    onMouseLeave={() => { onMouseLeave('HOME') }}>
                                    HOME
                                </a>
                                <div className="flex-column">
                                    <div className="menu-1" style={{ backgroundColor: (menuProductOver == 1 ? '#eeeeee' : 'transparent') }}
                                        onClick={() => { onClick('PRODUCT') }}
                                        onMouseOver={() => { onMouseOver('PRODUCT') }}
                                        onMouseLeave={() => { onMouseLeave('PRODUCT') }}>
                                        PRODUCT
                                    </div>
                                    <div style={{ position: 'relative', bottom: 0, zIndex: 1 }}>
                                        <div className="menu-2" style={{ display: (menuProductOpen == 1 ? 'flex' : 'none'), }}
                                            onMouseLeave={() => { onProductMenuLeave(0) }} >
                                            <a href="/hardware1"
                                                className="menu-3"
                                                style={{ backgroundColor: (productMenuHover == 1 ? '#e0e0e0' : 'transparent'), cursor: 'pointer' }}
                                                onMouseOver={() => { onProductMenuHover(1) }}>
                                                HARDWARE
                                            </a>
                                            <a href="/software" className="menu-3"
                                                style={{
                                                    backgroundColor: (productMenuHover == 2 ? '#e0e0e0' : 'transparent'), cursor: 'pointer'
                                                }} onMouseOver={() => { onProductMenuHover(2) }}>
                                                SOFTWARE
                                            </a>
                                            <a href="/solutionaddon" className="menu-3" style={{
                                                backgroundColor: (productMenuHover == 3 ? '#e0e0e0' : 'transparent'), cursor: 'pointer'
                                            }}
                                                onMouseOver={() => { onProductMenuHover(3) }}>
                                                SOLUTION ADDON
                                            </a>
                                            <a href="/solutionbusiness" className="menu-3" style={{
                                                backgroundColor: (productMenuHover == 4 ? '#e0e0e0' : 'transparent'), cursor: 'pointer'
                                            }} onMouseOver={() => { onProductMenuHover(4) }}>
                                                SOLUTION BUSINESS
                                            </a>
                                        </div>
                                    </div>
                                </div>


                                <a href="/ContactUs"
                                    className="menu-1"
                                    style={{
                                        backgroundColor: (menuContactUsOver == 1 ? '#eeeeee' : 'transparent')

                                    }}
                                    onMouseOver={() => { onMouseOver('CONTACTUS') }}
                                    onMouseLeave={() => { onMouseLeave('CONTACTUS') }}>
                                    CONTACT US
                                </a>

                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        flex: 10,
                    }}>
                    </div>
                </div>



                {/** Banner */}
                <div className="linear-bg" style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    height: 80,
                    color: 'white'
                }}>
                    <div style={{
                        display: 'flex',
                        flex: 10,
                        flexDirection: 'row',
                        height: 80,
                        color: 'white'
                    }}>

                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 80,
                        height: 80,
                        justifyItems: 'center',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        color: 'white',
                        fontSize: 25,
                        fontFamily: 'Comic Neue',
                        fontWeight: 'bold',
                        fontStyle: 'italic'
                    }}>
                        Your Software and Hardware Solution
                    </div>
                    <div style={{
                        display: 'flex',
                        flex: 10,
                        flexDirection: 'row',
                        height: 80,
                        color: 'white'
                    }}>
                    </div>
                </div>

                <div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'sticky',
                    height: 80,
                    backgroundColor: 'white',
                    top: 0,
                }}>

                    <div style={{
                        display: 'flex',
                        flex: 10,
                    }}>

                    </div>

                    <div style={{
                        display: 'flex',
                        flex: 80,
                        flexDirection: 'column',
                    }}>
                        <Outlet />
                    </div>
                    <div style={{
                        display: 'flex',
                        flex: 10,
                    }}>
                    </div>
                </div>


                {/** Footer */}
                <div style={{
                    display: 'flex',
                    flex: 1,
                    height: 100,
                    boxSizing: 'border-box',
                    backgroundColor: 'rgba(00,00,00,1)',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',

                }}>
                    <div
                        className="font-family-quicksand"
                        style={{
                            display: 'flex',
                            fontSize: 13,
                            fontWeight: 'bold',
                            paddingBottom: 5,
                            color: 'white'
                        }}>
                        &copy; 2024 PT. Divix Tech Indonesia
                    </div>
                    <div className="font-family-quicksand"
                        style={{ display: 'flex', fontSize: 13, color: 'white' }}>
                        Jl. Jimbaran 15 No. 66, Perumahan Citra Maja Raya 2
                    </div>
                    <div className="font-family-quicksand"
                        style={{ display: 'flex', fontSize: 13, paddingBottom: 5, color: 'white' }}>
                        Cluster Jimbaran Blok I.16, Lebak, Banten 42381
                    </div>
                    <div className="font-family-quicksand"
                        style={{ display: 'flex', fontSize: 12, fontStyle: 'italic', color: 'white' }}>
                        Phone: +62 851 7863 7624, Email: admin@divixtech.com
                    </div>
                </div>

                <a href="https://wa.me/+6285176847624/?text=urlencodedtext">
                    <img src="./wawa.png"
                        style={{
                            position: "fixed", bottom: 20, right: 20, width: 70, height: 70,
                        }} alt="DIVIX"
                    />
                </a>
            </div>
        </>;
    }



}
export default Layout;