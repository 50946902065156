import '../Global.css';

const SolutionBusiness = () => {
  return <>
    <div style={{
      display: 'flex',
      flexDirection: 'row',
    }}>


      <div style={{ display: 'flex', flex: 10, }} />

      {/** MAIN CONTENT */}
      <div style={{
        display: 'flex',
        flex: 80,
        flexDirection: 'column'
      }}>

        {/* HEADER */}
        <div style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          <div
            className='title-heading-01'
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            Solution Business
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            lineHeight: 1.5,
            height: 500
          }}>
          </div>
        </div>




      </div>
      <div style={{ display: 'flex', flex: 10, }} />
    </div>
  </>
};

export default SolutionBusiness;