import { createSlice } from '@reduxjs/toolkit';

export interface ITimer {
    counter: number,
}

const initialState: ITimer = {
    counter: 0,
}

export const timerSlice = createSlice({
    name: 'timer',
    initialState,
    reducers: {
        updateTimer: (state, action) => {
            if (action.payload.type === "counter") {
                state.counter = action.payload.payload;
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const { updateTimer } = timerSlice.actions

export default timerSlice.reducer