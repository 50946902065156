import '../Global.css';

import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from '@react-hook/window-size'


const Hardware = () => {

  const [width, height] = useWindowSize();


  if (width <= 768)
    return <>
      <div className='flex-column'>

        {/** MAIN CONTENT */}
        <div className='flex-column flex-container-center' >

          {/* HEADER */}
          <div className='flex-column'>
            {/* HEADER */}
            <div className='flex-column'>
              <div className='font-heading-1'>
                HARDWARE
              </div>
              <div className="font-content">
                Perusahaan kami menjual berbagai jenis brand hardware dan bergaransi resmi dari principal.
                Beberapa merek terkenal yang bekerja sama dengan kami adalah Lenovo, Hewlet Packard (HP), ASUS, Apple dan brand lainnya.
                Serta kami memiliki layanan purna jual yang baik untuk pelanggan kami.
              </div>
            </div>

            {/* PRINTER */}
            <div className='flex-column'>
              <div className='flex-column'>
                <img src="./printer.png" className='img-product' alt="DIVIX" />
              </div>
              <div className='flex-column flex-product-container-right'>
                <div className='div-separator-10-vertical' />
                <div className='font-heading-2'>
                  PRINTER
                </div>
                <div className="flex-column font-content">
                  Beberapa Brand Printer yang kami jual adalah sebagai berikut:
                  <br />
                  <div className="font-heading-4">Hewlet Packard (HP)</div>
                  HP InkJet/Smart Tank, HP DeskJet Ink Advantage, HP LaserJet, HP OfficeJet, HP ScanJet, HP PageWide, HP Large Format/ DesignJet / Plotter.
                  <div className="font-heading-4">Epson</div>
                  Epson InkTank, Epson InkJet, Epson DotMatrix, Epson InkCatridge, Epson Label & Press,
                  Epson Large Format,
                  Epson Direct-to-Fabric, Epson Laser, Epson POS & Epson Photo Lab.
                  <div className="font-heading-4">Canon</div>
                  Canon InkTank Printer, Canon InkJet Printer, Canon InkTank Photo Printer,
                  Canon Professional Photo Printer, Canon Laser Printer & Canon Mobile Printer.
                </div>
              </div>
            </div>

            {/* LAPTOP */}
            <div className='flex-column'>
              <div className='flex-column flex-product-container-left'>
                <img src="./laptop.png" className='img-product' alt="DIVIX" />
              </div>
              <div className='flex-column flex-product-container-right'>
                <div className='div-separator-10-vertical' />
                <div className='font-heading-2'>
                  LAPTOP
                </div>
                <div className="flex-column font-content">
                  Beberapa brand yang kami jual adalah:
                  <br />
                  <div className="font-heading-4">Lenovo</div>
                  Lenovo Thinkpad, Lenovo Thinkbook, Lenovo Ideapad, Lenovo LOQ , Lenovo Yoga & Lenovo Legion.
                  <div className="font-heading-4">ASUS</div>
                  Asus Vivobook, Asus Zenbook dan Asus ProArt Studiobook.
                  <div className="font-heading-4">Apple</div>
                  Apple Macbook Pro dan Apple Macbook Air.
                  <div className="font-heading-4">MSI</div>
                  MSI Summit, MSI Prestige, MSI  Modern &  MSI Commercial.
                  <div className="font-heading-4">HP</div>
                  HP Spectre, HP ENVY, HP Pavilion, HP Essential, HP OMEN, HP Victus, HP Ellitebook, HP  Probook & HP ZBook.
                  <div className="font-heading-4">DELL</div>
                  DELL XPS, DELL Latitude, DELL Inspiron, DELLVostro, DELL Alienware dan DELL G Series.
                </div>
              </div>
            </div>


            {/**Desktop / PC */}
            <div className='flex-column'>
              <div className='flex-column flex-product-container-left'>
                <img src="./desktop.png" className='img-product' alt="DIVIX" />
              </div>
              <div className='flex-column flex-product-container-right'>
                <div className='div-separator-10-vertical' />
                <div className='font-heading-2'>
                  DESKTOP/PC
                </div>
                <div className="flex-column font-content">
                  Beberapa brand yang kami jual adalah:
                  <div className="font-heading-4">Lenovo</div>
                  Lenovo ThinkCenter, Lenovo IdeaCenter & Lenovo Legion.
                  <div className="font-heading-4">Apple</div>
                  Apple iMac, Apple Mac Mini, Apple Mac Studio dan Apple Mac Pro.
                  <div className="font-heading-4">MSI</div>
                  MSI Aegis, MSI Infinite,  dan MSI Trident.
                  <div className="font-heading-4">HP</div>
                  HP EliteDesk, HP ProDesk, HP Z WorkStatio, dan HP Essential.
                  <div className="font-heading-4">DELL</div>
                  DELL OptiPlex, DELL Inspiron, DELL Vostro dan DELL Alienware.
                  <div className="font-heading-4">Custom PC/Rakitan</div>
                  Kami juga menyediakan Layanan perakitan PC/Desktop untuk kebutuhan custom anda.

                </div>
              </div>
            </div>


            {/**SERVER  */}
            <div className='flex-server'>
              <div className='flex-column flex-product-container-left'>
                <img src="./server.png" className='img-product' alt="DIVIX" />
              </div>
              <div className='flex-column flex-product-container-right'>
                <div className='font-heading-2'>
                  SERVER
                </div>
                <div className="flex-column font-content">
                  Beberapa brand yang kami jual adalah:
                  <div className="font-heading-4">Lenovo</div>
                  Lenovo ThinkCenter
                  <div className="font-heading-4">IBM</div>
                  IBM Z Series, IBM Porwer Series
                </div>
              </div>
            </div>

          </div>;
        </div>

      </div>
    </>
  else
    return <>
      <div className='flex-row'>

        <div className='flex-row flex-container-left' />

        {/** MAIN CONTENT */}
        <div className='flex-column flex-container-center' >

          {/* HEADER */}
          <div className='flex-column'>
            {/* HEADER */}
            <div className='flex-column'>
              <div className='font-heading-1'>
                HARDWARE
              </div>
              <div className="font-content">
                Perusahaan kami menjual berbagai jenis brand hardware dan bergaransi resmi dari principal.
                Beberapa merek terkenal yang bekerja sama dengan kami adalah Lenovo, Hewlet Packard (HP), ASUS, Apple dan brand lainnya.
                Serta kami memiliki layanan purna jual yang baik untuk pelanggan kami.
              </div>
            </div>


            {/* PRINTER */}
            <div className='flex-row'>
              <div className='flex-column flex-product-container-left'>
                <img src="./printer.png" className='img-product' alt="DIVIX" />
              </div>
              <div className='flex-column flex-product-container-right'>
                <div className='div-separator-10-vertical' />
                <div className='font-heading-2'>
                  Printer
                </div>
                <div className='div-separator-10-vertical' />
                <div className="flex-column font-content">
                  Beberapa Brand Printer yang kami jual adalah sebagai berikut:
                  <br />
                  <div className="font-heading-4">Hewlet Packard (HP)</div>
                  HP InkJet/Smart Tank, HP DeskJet Ink Advantage, HP LaserJet, HP OfficeJet, HP ScanJet, HP PageWide, HP Large Format/ DesignJet / Plotter.
                  <div className="font-heading-4">Epson</div>
                  Epson InkTank, Epson InkJet, Epson DotMatrix, Epson InkCatridge, Epson Label & Press,
                  Epson Large Format,
                  Epson Direct-to-Fabric, Epson Laser, Epson POS & Epson Photo Lab.
                  <div className="font-heading-4">Canon</div>
                  Canon InkTank Printer, Canon InkJet Printer, Canon InkTank Photo Printer,
                  Canon Professional Photo Printer, Canon Laser Printer & Canon Mobile Printer.
                </div>
              </div>
            </div>

            {/* LAPTOP */}
            <div className='flex-row'>
              <div className='flex-column flex-product-container-left'>
                <img src="./laptop.png" className='img-product' alt="DIVIX" />
              </div>
              <div className='flex-column flex-product-container-right'>
                <div className='div-separator-10-vertical' />
                <div className='font-heading-2'>
                  Laptop
                </div>
                <div className="flex-column font-content">
                  Beberapa brand yang kami jual adalah:
                  <br />
                  <div className="font-heading-4">Lenovo</div>
                  Lenovo Thinkpad, Lenovo Thinkbook, Lenovo Ideapad, Lenovo LOQ , Lenovo Yoga & Lenovo Legion.
                  <div className="font-heading-4">ASUS</div>
                  Asus Vivobook, Asus Zenbook dan Asus ProArt Studiobook.
                  <div className="font-heading-4">Apple</div>
                  Apple Macbook Pro dan Apple Macbook Air.
                  <div className="font-heading-4">MSI</div>
                  MSI Summit, MSI Prestige, MSI  Modern &  MSI Commercial.
                  <div className="font-heading-4">HP</div>
                  HP Spectre, HP ENVY, HP Pavilion, HP Essential, HP OMEN, HP Victus, HP Ellitebook, HP  Probook & HP ZBook.
                  <div className="font-heading-4">DELL</div>
                  DELL XPS, DELL Latitude, DELL Inspiron, DELLVostro, DELL Alienware dan DELL G Series.
                </div>
              </div>
            </div>


            {/**Desktop / PC */}
            <div className='flex-row'>
              <div className='flex-column flex-product-container-left'>
                <img src="./desktop.png" className='img-product' alt="DIVIX" />
              </div>
              <div className='flex-column flex-product-container-right'>
                <div className='div-separator-10-vertical' />
                <div className='font-heading-2'>
                  Desktop/PC
                </div>
                <div className="flex-column font-content">
                  Beberapa brand yang kami jual adalah:
                  <div className="font-heading-4">Lenovo</div>
                  Lenovo ThinkCenter, Lenovo IdeaCenter & Lenovo Legion.
                  <div className="font-heading-4">Apple</div>
                  Apple iMac, Apple Mac Mini, Apple Mac Studio dan Apple Mac Pro.
                  <div className="font-heading-4">MSI</div>
                  MSI Aegis, MSI Infinite,  dan MSI Trident.
                  <div className="font-heading-4">HP</div>
                  HP EliteDesk, HP ProDesk, HP Z WorkStatio, dan HP Essential.
                  <div className="font-heading-4">DELL</div>
                  DELL OptiPlex, DELL Inspiron, DELL Vostro dan DELL Alienware.
                  <div className="font-heading-4">Custom PC/Rakitan</div>
                  Kami juga menyediakan Layanan perakitan PC/Desktop untuk kebutuhan custom anda.

                </div>
              </div>
            </div>


            {/**SERVER  */}
            <div className='flex-row'>
              <div className='flex-column flex-product-container-left'>
                <img src="./server.png" className='img-product' alt="DIVIX" />
              </div>
              <div className='flex-column flex-product-container-right'>
                <div className='font-heading-2'>
                  Server
                </div>
                <div className="flex-column font-content">
                  Beberapa brand yang kami jual adalah:
                  <div className="font-heading-4">Lenovo</div>
                  Lenovo ThinkCenter
                  <div className="font-heading-4">IBM</div>
                  IBM Z Series, IBM Porwer Series
                </div>
              </div>
            </div>

          </div>;
        </div>
        <div className='flex-row flex-container-left' />
      </div>
    </>
};

export default Hardware;