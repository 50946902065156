import '../Global.css';

import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from '@react-hook/window-size'

const SolutionAddOn = () => {

  const [width, height] = useWindowSize();

  if (width <= 768)
    return <>
      <div className='flex-column'>

        {/** MAIN CONTENT */}

        {/* HEADER */}
        <div className='flex-column'>

          <div className='font-heading-1'>
            SOLUTION ADDON
          </div>
          <div className='font-content'>
            Perusahaan kami menjual berbagai jenis brand hardware dan bergaransi resmi dari principal.
            Beberapa merek terkenal yang bekerja sama dengan kami adalah Lenovo, Hewlet Packard (HP), ASUS, Apple dan brand lainnya.
            Serta kami memiliki layanan purna jual yang baik untuk pelanggan kami.
          </div>
        </div>

        {/* API */}
        <div className='flex-column'>
          <div className='flex-column'>
            <img src="./api.png" className='img-product' alt="DIVIX" />
          </div>
          <div className='flex-column flex-product-container-right'>
            <div className='div-separator-10-vertical' />
            <div className='font-heading-2'>
              API (APPLICATION PROGRAMMING INTERFACE)
            </div>
            <div className='div-separator-10-vertical' />
            <div className='font-content'>
              Anda membutuhkan RESTful API yang tangguh dan handal untuk mendukung berbagai proyek Anda?
              Layanan pembuatan RESTful API / REST API CRUD kami adalah solusi serbaguna yang mencakup PHP,
              NodeJS, Python, dan Golang. Dengan harga yang sangat terjangkau, kami menyediakan kemampuan untuk
              menciptakan API yang efisien dan responsif sesuai dengan bahasa pemrograman yang Anda butuhkan
            </div>
          </div>
        </div>


        {/* Web Apps */}
        <div className='flex-column'>
          <div className='flex-column'>
            <img src="./webapp.png" className='img-product' alt="DIVIX" />
          </div>
          <div className='flex-column flex-product-container-right'>
            <div className='div-separator-10-vertical' />
            <div className='font-heading-2'>
              WEB APPLICATION
            </div>
            <div className='div-separator-10-vertical' />
            <div className='font-content'>
              Kami juga menyediakan layanan Jasa Digital Marketing yang dapat membantu meningkatkan brand awareness & penjualan dalam bisnis anda
              berbagai macam layanan jasa pembuatan website untuk berbagai keperluan diantaranya adalah:
              <br />
              * Pembuatan Web Perusahaan / Company Profile
              <br />
              * Pembuatan Web Sekolah / Universitas
              <br />
              * Pembuatan Web Toko Online
              <br />
              * Pembuatan Web Rental Mobil, Bus, Motor dll
              <br />
              * Pembuatan Web Organisasi
              <br />
              * Pembuatan Web Blog
              <br />
              * Pembuatan Web LandingPage
              <br />
              * Web Custome
              <br />
              * dll
            </div>
          </div>
        </div>


        {/* Middleware */}
        <div className='flex-column'>
          <div className='flex-column'>
            <img src="./middleware.png" className='img-product' alt="DIVIX" />
          </div>
          <div className='flex-column flex-product-container-right'>
            <div className='div-separator-10-vertical' />
            <div className='font-heading-2'>
              MIDDLEWARE APPLICATION
            </div>
            <div className='div-separator-10-vertical' />
            <div className='font-content'>
              Tim kami berpengalaman dalam pembuatan middleware kustom untuk menangani autentikasi, otorisasi, dan berbagai kebutuhan keamanan lainnya.
            </div>
          </div>
        </div>


        {/* Mobile Apps */}
        <div className='flex-column'>
          <div className='flex-column'>
            <img src="./mobileapp.png" className='img-product' alt="DIVIX" />
          </div>
          <div className='flex-column flex-product-container-right'>
            <div className='div-separator-10-vertical' />
            <div className='font-heading-2'>
              Mobile Application
            </div>
            <div className='div-separator-10-vertical' />
            <div className='font-content'>
              Tim Professional kami akan membantu anda dalam mengembangkan aplikasi mobile yang anda inginkan dan sesuai dengan kebutuhan anda
            </div>
          </div>
        </div>

        {/* Network */}
        <div className='flex-column'>
          <div className='flex-column'>
            <img src="./network.png" className='img-product' alt="DIVIX" />
          </div>
          <div className='flex-column flex-product-container-right'>
            <div className='div-separator-10-vertical' />
            <div
              className='font-heading-2'>
              Network Design and Security
            </div>
            <div className='div-separator-10-vertical' />
            <div className='font-content'>
              Kami menawarkan jasa instalasi jaringan, kamu bisa menggunakan jasa kami untuk instalasi jaringan di perusahaan, tempat usaha dan bisa juga di rumah kamu
            </div>
          </div>
        </div>
      </div>
    </>

  else
    return <>
      <div className='flex-row'>

        <div className='flex-row flex-container-left' />

        {/** MAIN CONTENT */}
        <div className='flex-column flex-container-center' >

          {/* HEADER */}
          <div className='flex-column'>

            <div className='font-heading-1'>
              Solution Add On
            </div>
            <div className='font-content'>
              Perusahaan kami menjual berbagai jenis brand hardware dan bergaransi resmi dari principal.
              Beberapa merek terkenal yang bekerja sama dengan kami adalah Lenovo, Hewlet Packard (HP), ASUS, Apple dan brand lainnya.
              Serta kami memiliki layanan purna jual yang baik untuk pelanggan kami.
            </div>
          </div>

          {/* API */}
          <div className='flex-row'>
            <div className='flex-row flex-product-container-left'>
              <img src="./api.png" className='img-product' alt="DIVIX" />
            </div>
            <div className='flex-column flex-product-container-right'>
              <div className='div-separator-10-vertical' />
              <div className='font-heading-2'>
                API (Application Programming Interface)
              </div>
              <div className='div-separator-10-vertical' />
              <div className='font-content'>
                Anda membutuhkan RESTful API yang tangguh dan handal untuk mendukung berbagai proyek Anda?
                Layanan pembuatan RESTful API / REST API CRUD kami adalah solusi serbaguna yang mencakup PHP,
                NodeJS, Python, dan Golang. Dengan harga yang sangat terjangkau, kami menyediakan kemampuan untuk
                menciptakan API yang efisien dan responsif sesuai dengan bahasa pemrograman yang Anda butuhkan
              </div>
            </div>
          </div>


          {/* Web Apps */}
          <div className='flex-row'>
            <div className='flex-column flex-product-container-left'>
              <img src="./webapp.png" className='img-product' alt="DIVIX" />
            </div>
            <div className='flex-column flex-product-container-right'>
              <div className='div-separator-10-vertical' />
              <div className='font-heading-2'>
                Web Application
              </div>
              <div className='div-separator-10-vertical' />
              <div className='font-content'>
                Kami juga menyediakan layanan Jasa Digital Marketing yang dapat membantu meningkatkan brand awareness & penjualan dalam bisnis anda
                berbagai macam layanan jasa pembuatan website untuk berbagai keperluan diantaranya adalah:
                <br />
                * Pembuatan Web Perusahaan / Company Profile
                <br />
                * Pembuatan Web Sekolah / Universitas
                <br />
                * Pembuatan Web Toko Online
                <br />
                * Pembuatan Web Rental Mobil, Bus, Motor dll
                <br />
                * Pembuatan Web Organisasi
                <br />
                * Pembuatan Web Blog
                <br />
                * Pembuatan Web LandingPage
                <br />
                * Web Custome
                <br />
                * dll
              </div>
            </div>
          </div>


          {/* Middleware */}
          <div className='flex-row'>
            <div className='flex-column flex-product-container-left'>
              <img src="./middleware.png" className='img-product' alt="DIVIX" />
            </div>
            <div className='flex-column flex-product-container-right'>
              <div className='div-separator-10-vertical' />
              <div className='font-heading-2'>
                Middleware Application
              </div>
              <div className='div-separator-10-vertical' />
              <div className='font-content'>
                Tim kami berpengalaman dalam pembuatan middleware kustom untuk menangani autentikasi, otorisasi, dan berbagai kebutuhan keamanan lainnya.
              </div>
            </div>
          </div>


          {/* Mobile Apps */}
          <div className='flex-row'>
            <div className='flex-column flex-product-container-left'>
              <img src="./mobileapp.png" className='img-product' alt="DIVIX" />
            </div>
            <div className='flex-column flex-product-container-right'>
              <div className='div-separator-10-vertical' />
              <div className='font-heading-2'>
                Mobile Application
              </div>
              <div className='div-separator-10-vertical' />
              <div className='font-content'>
                Tim Professional kami akan membantu anda dalam mengembangkan aplikasi mobile yang anda inginkan dan sesuai dengan kebutuhan anda
              </div>
            </div>
          </div>

          {/* Network */}
          <div className='flex-row'>
            <div className='flex-column flex-product-container-left'>
              <img src="./network.png" className='img-product' alt="DIVIX" />
            </div>
            <div className='flex-column flex-product-container-right'>
              <div className='div-separator-10-vertical' />
              <div
                className='font-heading-2'>
                Network Design and Security
              </div>
              <div className='div-separator-10-vertical' />
              <div className='font-content'>
                Kami menawarkan jasa instalasi jaringan, kamu bisa menggunakan jasa kami untuk instalasi jaringan di perusahaan, tempat usaha dan bisa juga di rumah kamu
              </div>
            </div>
          </div>
        </div>
        <div className='flex-row flex-container-left' />
      </div>
    </>
};

export default SolutionAddOn;